<template>
  <el-dialog :close-on-click-modal="false" v-model="visible" title="资产拆分" width="600px" destroy-on-close>
    <el-form :model="temp" size="small" :rules="rules" ref="ruleFormRef">
      <el-form-item label="拆分数量：" prop="count">
        <qzf-input v-model:num="temp.count" class="w-130" @changeLx="changeCount" />
        <span class="split_style">资产数量：{{ assetInfo.count }}</span>
      </el-form-item>
      <el-form-item label="拆分原值：" prop="originalValue">
        <qzf-input v-model:num="temp.originalValue" class="w-130" />
        <span class="split_style">资产原值：{{ assetInfo.originalValue }}</span>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="visible = false">取消</el-button>
        <el-button size="small" type="primary" @click="save">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref } from 'vue'
import { ElMessage } from 'element-plus'
import { assetSplit } from '@/api/assets'
const emit = defineEmits(['success'])
const assetInfo = ref({})
const visible = ref(false)
const temp = ref({
  assetId: 0,
  originalValue: 0,
  count: 0
})
const ruleFormRef = ref()
const rules = {
  count: [
    { required: true, message: '请输入拆分数量', trigger: 'blur' },
  ],
  originalValue: [
    { required: true, message: '请输入拆分原值', trigger: 'blur' },
  ]
}
const init = (row) => {
  assetInfo.value = row
  temp.value = {
    assetId: row.id,
    originalValue: 0,
    count: 0
  }
  visible.value = true
}
const changeCount = () => {
  if (temp.value.count > assetInfo.value.count) {
    ElMessage.warning('拆分数量不能大于原数量')
    return
  }
  temp.value.originalValue = assetInfo.value.count ? Number((temp.value.count / assetInfo.value.count * assetInfo.value.originalValue).toFixed(2)) : 0
}
const save = () => {
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      if(!temp.value.originalValue){
        ElMessage.warning('拆分原值不能为空')
        return
      }
      assetSplit(temp.value).then(res => {
        if (res.data.msg == 'success') {
          ElMessage.success('拆分成功')
          visible.value = false
          emit('success')
        }
      })
    }
  })
}
defineExpose({
  init
})
</script>

<style scoped lang="scss">
.split_style {
  margin-left: 20px;
}
</style>
