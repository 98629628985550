<template>
  <div style="position: relative;">
    <div style="position: absolute;top: 10px;right: 15px;z-index: 9999;">
      <qzf-video vid="63dd60f5881166d5b24734d6bc765461_6"></qzf-video>
    </div>
    <el-tabs v-model="listQuery.type" @tab-click="handleClick" type="border-card">
      <el-tab-pane label="固定资产" :name="1"></el-tab-pane>
      <el-tab-pane label="无形资产" :name="2"></el-tab-pane>
      <el-tab-pane label="费用摊销" :name="3"></el-tab-pane>
      <div class="content_data">
        <div
          style="display: flex; align-items: center;width:98%;margin: 0 auto;height:70px"
        >
          <div style="width:8%">统计</div>
          <div class="each">
            <div class="icon_bg">
              <i class="iconfont icon-zichan1"></i>
            </div>
            <div
              style="margin-left:5px"
            >
              <div style="margin-bottom:5px">原值</div>
                <span>{{ sumList.originalValue }}</span> 
            </div>
          </div>
          <div class="each">
            <div class="icon_bg">
              <i class="iconfont icon-jiezhangdayin1"></i>
            </div>
            <div
              style="margin-left:5px"
            >
              <div style="margin-bottom:5px">本月折旧</div>
                <span>{{ sumList.amortizeAmount }}</span> 
            </div>
          </div>
          <!-- <div class="each">
            <div class="icon_bg">
              <i class="iconfont icon-lishishujucaiji"></i>
            </div>
            <div
              style="margin-left:5px"
            >
              <div style="margin-bottom:5px">本年折旧</div>
                <span>{{ sumList.amortizeAmount }}</span> 
            </div>
          </div> -->
          <div class="each">
            <div class="icon_bg">
              <i class="iconfont icon-yewushuju"></i>
            </div>
            <div
              style="margin-left:5px"
            >
              <div style="margin-bottom:5px">累计折旧</div>
                <span>{{ sumList.ljDepreciation }}</span> 
            </div>
          </div>
          <div class="each">
            <div class="icon_bg">
              <i class="iconfont icon-xinchou"></i>
            </div>
            <div
              style="margin-left:5px"
            >
              <div style="margin-bottom:5px">净值</div>
                <span>{{ sumList.netValue < 0?0: sumList.netValue }}</span> 
            </div>
          </div>
        </div>
      </div>
      <div class="left_search">
        <el-input size="small" v-model="listQuery.name" placeholder="请输入费用名称" v-if="listQuery.type == 3" style="width:200px" @keyup.enter="getList" clearable></el-input>
        <el-input size="small" v-model="listQuery.name" placeholder="请输入资产名称" v-else style="width:200px" @keyup.enter="getList" clearable></el-input>
        <el-button type="primary" size="small" @click="getList" style="margin-right:10px">
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form style="margin:21px 20px 0px 6px;width:360px" class="styleForm">
            <el-form-item label="费用类型 ：" label-width="120px" v-if="listQuery.type == 3">
              <el-select v-model="listQuery.assetType" placeholder="请选择费用类型" style="width:200px;margin:0" size="small" clearable >
                <el-option label="开办费" value="开办费"></el-option>
                <el-option label="固定资产修理支出" value="固定资产修理支出"></el-option>
                <el-option label="租入固定资产的改良支出" value="租入固定资产的改良支出"></el-option>
                <el-option label="已足额折旧资产改建" value="已足额折旧资产改建"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="资产类别 ：" label-width="120px" v-else>
              <el-select v-model="listQuery.assetType" placeholder="请选择资产类别" style="width:200px;margin:0" size="small" clearable>
                <el-option label="房屋、建筑物" value="房屋、建筑物"></el-option>
                <el-option label="飞机、火车、轮船、机器、机械和其他生产设备" value="飞机、火车、轮船、机器、机械和其他生产设备"></el-option>
                <el-option label="生产经营活动有关的器具、工具、家具" value="生产经营活动有关的器具、工具、家具"></el-option>
                <el-option label="飞机、火车、轮船以外的运输工具" value="飞机、火车、轮船以外的运输工具"></el-option>
                <el-option label="电子设备" value="电子设备"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态 ：" label-width="120px">
              <el-select v-model="listQuery.status" placeholder="请选择" style="width:200px;margin:0" size="small" >
                <el-option label="全部" :value="5"></el-option>
                <el-option label="正常" :value="1"></el-option>
                <el-option label="暂停" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="成本费用科目 ：" label-width="120px" v-if="listQuery.type == 3">
              <subject-list v-model:subjectId="listQuery.fySubjectId"></subject-list>
            </el-form-item>
            <el-form-item label="折旧费用科目 ：" label-width="120px" v-else>
              <subject-list v-model:subjectId="listQuery.fySubjectId"></subject-list>
            </el-form-item>
            <el-form-item label="折旧完成状态 ：" label-width="120px">
              <el-select v-model="listQuery.depOk" placeholder="请选择" style="width:200px;margin:0" size="small" >
                <el-option label="全部" :value="0"></el-option>
                <el-option label="已折旧完成" :value="1"></el-option>
                <el-option label="未折旧完成" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </search>
        <span style="margin-left:10px"><el-switch v-model="listQuery.ql" active-text="显示已清理资产" inactive-text="" @change="getList" v-if="listQuery.type == 1"></el-switch></span>
        <p style="display:inline-block;font-size:14px;color:red;line-height:28px;margin-left:10px">温馨提示：<span style="color:#333;">非期初的资产通过【发票】-【进项】增加</span></p>
      </div>
      <div class="right_btns">
        <!-- <qzf-video v-if="this.listQuery.type == 1" vid="4467be53745ca91fd9fdc3ee2002f393_4"></qzf-video>
        <qzf-video v-if="this.listQuery.type == 2" vid="4467be53744382b1e11fb51b9ce2f720_4"></qzf-video>
        <qzf-video v-if="this.listQuery.type == 3" vid="4467be537465c94c4d520cbd4b5013aa_4"></qzf-video> -->


        <el-dropdown split-button size="small" type="primary" style="margin-right:10px" :hide-on-click="false" @click="handleCreate" v-if="$buttonStatus('zc_xz')">
          <i class="iconfont icon-jiahao"></i> 添加
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <qzf-button jz="true" size="small" type="text" @success="openCom">导入</qzf-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <qzf-button button_code="zc_plsc" plain type="danger" size="small" v-if="listQuery.type == 1" @success="assetsDelete"   >
          <el-icon><Delete /></el-icon> <span  >删除</span>
        </qzf-button>
        <!-- <qzf-button type="primary" size="small" plain>导出</qzf-button> -->
        <!-- <qzf-button type="primary" size="small" plain v-if="listQuery.type == 1">导入</qzf-button> -->
        <el-button type="success" size="small"  @click="printAssets" v-if="$buttonStatus('zc_dy')">
          <el-icon><Printer /></el-icon> <span  >打印</span>
        </el-button>
        <!-- <qzf-button type="primary" size="small"  @click="printAssets">
          <el-icon><Printer /></el-icon> <span  >打印凭证纸</span>
        </qzf-button> -->
        <el-button type="primary" size="small"  @click="exportAssets" v-if="$buttonStatus('zc_dc')">
          <el-icon><FolderOpened /></el-icon> <span  >导出</span>
        </el-button>
        <el-dropdown @command="handleCommand" size="small"  style="margin-left:10px" >
          <el-button size="small" type="primary">
            更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </el-button>
          <template #dropdown>
            <el-dropdown-menu style="text-align:center">
              <el-dropdown-item command="a" v-if="$buttonStatus('zc_ycxzj')">
                <span v-if="listQuery.type == 1">一次性折旧</span>
                <span v-else>一次性摊销</span>
              </el-dropdown-item>
              <el-dropdown-item command="b" v-if="$buttonStatus('zc_qxzj')">
                <span style="margin-top:3px" plain v-if="listQuery.type == 1">取消折旧</span>
                <span v-else>取消摊销</span>
              </el-dropdown-item>
              <el-dropdown-item command="d" v-if="$buttonStatus('zc_ztzj')">
                <span style="margin-top:3px" plain v-if="listQuery.type == 1">暂停折旧</span>
                <span v-else>暂停摊销</span>
              </el-dropdown-item>
              <el-dropdown-item command="c" v-if="$buttonStatus('zc_hfzj')">
                <span style="margin-top:3px" plain v-if="listQuery.type == 1">恢复折旧</span>
                <span v-else>恢复摊销</span>
              </el-dropdown-item>
              <el-dropdown-item command="f" v-if="$buttonStatus('zc_qlzc') && listQuery.type == 1" >
                <span style="margin-top:3px" plain>清理资产</span>
              </el-dropdown-item>
              <el-dropdown-item command="e" v-if="$buttonStatus('zc_jzzc') && listQuery.type == 1">
                <span style="margin-top:3px" plain>结转资产</span>
              </el-dropdown-item>
              
              <!-- <el-dropdown-item command="g" style="margin-bottom:5px">
                <span style="margin-top:3px" plain>导出</span>
              </el-dropdown-item> -->
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <el-table stripe :data="list" style="width: 100%" show-summary :summary-method="getSummaries" border fit highlight-current-row :height="contentStyleObj" @selection-change="handleSelectionChange" class="onlyTooltip">
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <el-table-column type="selection" align="center" width="55"/>
        <el-table-column label="序号" type="index" align="center" fixed width="55"></el-table-column>
        <el-table-column prop="name" v-if="listQuery.type == 3" fixed label="费用名称" align="center" min-width="90">
          <template #default="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" v-else label="资产名称" fixed align="center" min-width="90" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="unit" label="单位" align="center" min-width="90" >
          <template #default="scope">
            <span>{{ scope.row.unit }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="spec" label="规格型号" align="center" min-width="90" >
          <template #default="scope">
            <span>{{ scope.row.spec }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="assetType" v-if="listQuery.type == 3" label="费用类型" align="center" width="150">
          <template #default="scope">
            <span>{{ scope.row.assetType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" v-else label="资产类型" align="center" width="150" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.assetType }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="subjectName" v-if="listQuery.type == 1" label="累计折旧科目" align="center" width="150" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.subjectName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" v-else label="累计摊销科目" align="center" width="150" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.subjectName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="fySubjectName" v-if="listQuery.type == 1" label="折旧费用科目" align="center" width="150" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.fySubjectName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="codeFy" v-else label="摊销费用科目" align="center" width="150" show-overflow-tooltip>
          <template #default="scope">
            <span>{{ scope.row.fySubjectName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="count" label="数量" align="center" width="60"> 
          <template #default="scope">
            <span>{{ scope.row.count }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="transDate" label="入账日期" align="center" width="120"> 
          <template #default="scope">
            <span>{{ scope.row.transDate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="originalValue" label="原值(元)" align="center" min-width="105"> 
          <template #default="scope">
            <span>{{ scope.row.originalValue }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="depreciationPeriods" label="预计使用月份" align="center" width="135"> 
          <template #default="scope">
            <span>{{ scope.row.depreciationPeriods }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" v-if="listQuery.type == 1" label="残值率(小数)" align="center" min-width="110"> 
          <template #default="scope">
            <!-- <span>{{ scope.row.rmValueRate*100}}%</span> -->
            <span>{{ scope.row.rmValueRate}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cz" v-if="listQuery.type == 1" label="残值(元)" align="center" min-width="100"> 
          <template #default="scope">
            <span>{{ (scope.row.rmValueRate * scope.row.originalValue).toFixed2() }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="amortizeAmount" label="本月折旧(元)" align="center" min-width="105"> 
          <template #default="scope">
            <span>{{ scope.row.amortizeAmount }}</span>
          </template>
        </el-table-column>
        <el-table-column label="期末" align="center">
          <el-table-column prop="ljDepreciation" label="累计折旧(元)" align="center" min-width="105"> 
          <template #default="scope">
            <span>{{ (scope.row.ljDepreciation + scope.row.initLjDepreciation).toFixed(2) }}</span>
          </template>
        </el-table-column>
          <el-table-column prop="netValue" label="净值(元)" align="center" min-width="105"> 
            <template #default="scope">
              <span>{{ scope.row.netValue < 0?0: scope.row.netValue }}</span>
          </template>
        </el-table-column>
        </el-table-column>
        <el-table-column label="状态" align="center" min-width="180"> 
          <template #default="scope">
            <span v-if="scope.row.clearPeriod">{{ scope.row.clearPeriod }}账期已清理<span v-if="scope.row.clearRemark">-{{ scope.row.clearRemark }}</span></span>
            <div v-else>
              <span v-if="scope.row.status == 2">暂停</span>
              <span v-else>正常</span>
            </div>    
          </template>
        </el-table-column>
        <el-table-column prop="name" label="操作" align="center" width="200" > 
          <template #default="scope">
            <div class="flex_center">
              <qzf-button jz="true"  size="small" @success="handleUpdate(scope.row)" button_code="zc_bj" v-if="!scope.row.clearPeriod" icon="Edit" link>
                编辑
              </qzf-button>
              <el-tooltip :disabled="!scope.row.clearPeriod" content="资产卡片已清理，无法删除" placement="top" effect="dark">
                <qzf-button jz="true" type="danger" link size="small" @success="handleModifyStatus(scope.row)" button_code="zc_plsc" :disabled="scope.row.clearPeriod ? true : false" icon="Delete">
                  删除
                </qzf-button>
              </el-tooltip>
              <el-dropdown size="small"  style="margin-left:10px" v-if="listQuery.type == 1">
                <el-button size="small" type="primary" link>
                  更多<el-icon class="el-icon--right"><arrow-down /></el-icon>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu style="text-align:center">
                    <el-dropdown-item command="a" @click="splitAsset(scope.row)">
                      <span>拆分</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
      </div>
    </el-tabs>

    <addAssets ref="addAssets" :type="listQuery.type" @success="getList" :title='title' ></addAssets>
  </div>
  <qzf-import-back :hideButton="true" @success="getList()" from="固定资产" ref="importBck" v-if="listQuery.type == 1"></qzf-import-back>
  <qzf-import-back :hideButton="true" @success="getList()" from="无形资产" ref="importBck" v-if="listQuery.type == 2"></qzf-import-back>
  <qzf-import-back :hideButton="true" @success="getList()" from="费用摊销" ref="importBck" v-if="listQuery.type == 3"></qzf-import-back>

  <printVouch ref="printVouch" name="assets"  :listQuery1="this.listQuery"></printVouch>
  <splitAsset ref="splitAssetRef" @success="getList" />
  <clearAssets ref="clearAssetsRef" />
</template>



<script>
import { assetList , deleteEaAssets , jzAsset , cancelAsset , assetTotalSaves} from '@/api/assets'
import { exportFixedAssets } from "@/api/export"


import addAssets from "./components/addAssets.vue"
import printVouch from "../../bookkeeps/components/printVouch.vue"
import splitAsset from './components/splitAsset.vue'
import clearAssets from './components/clearAssets.vue'
export default {
  name: "assets",
  components:{
    addAssets,
    printVouch,
    splitAsset,
    clearAssets
  },
  data() {
    return {
      title:'固定资产',
      list: [],
      sumList:[],
      listQuery: {
        assetType: '',
        page: 1,
        limit: 20,
        type: 1,
        comId:this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        status:5,
        depOk:0
      },
      total:0,
      selects:[],
      comId: this.$store.getters['user/comInfo'].comId*1,
      ids:[]

    }
  },
  deactivated(){
    if(!this.$getCachedViews("assets")){//销毁 mitt bus
      this.$bus.off("assetsUpdate")
    }
  },
  created() {
    this.contentStyleObj= this.$getHeight(325)
    this.getList()
    this.initBus()
  },
  methods:{
    initBus(){
      this.$bus.on("assetsUpdate", () => {
        this.getList()
      });
    },
    getList(){
      assetList(this.listQuery).then(res=>{
        if(res.data.data.list){
          this.list = res.data.data.list
          this.total = res.data.data.total
        }else{
          this.list = []
          this.total = 0
        }
        this.sumList = res.data.data.sum
      })
    },
    //固定资产拆分
    splitAsset(row){
      if(this.$checkSettleStatus()) return
      if(row.clearPeriod){
        this.$qzfMessage("该资产" + row.clearPeriod + "账期已清理，无法拆分", 1)
        return
      }
      this.$refs.splitAssetRef.init(row)
    },
    handleCreate() {
      if(this.$checkSettleStatus()) return
      this.$refs.addAssets.openDialog()
    },
    handleCommand(command) {
      if(this.$checkSettleStatus()) return
      if(command == 'a'){
        if(this.selects.length == 0){
          this.$qzfMessage("请选择", 1)
          return
        }
        let param = {
          items:this.selects,
          type:1
        }
        cancelAsset(param).then(res=>{
          if(res.data.msg == "success"){
            if(this.listQuery.type == 1){
              this.$qzfMessage("折旧成功")
            }else{
              this.$qzfMessage("摊销成功")
            }
            this.getList()
          }
        })
      }else if(command == 'b'){
        if(this.selects.length == 0){
          this.$qzfMessage("请选择", 1)
          return
        }
        let param = {
          items:this.selects,
          type:2
        }
        cancelAsset(param).then(res=>{
          if(res.data.msg == "success"){
            if(this.listQuery.type == 1){
              this.$qzfMessage("取消折旧成功")
            }else{
              this.$qzfMessage("取消摊销成功")
            }
            this.getList()
          }
        })
      }else if(command == 'c'){
        if(this.selects.length == 0){
          this.$qzfMessage("请选择", 1)
          return
        }
        this.selects.map(v=>{
          v.status = 1
        })
        assetTotalSaves(this.selects).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("修改成功")
            this.getList()
          }
        })
      }else if(command == 'd'){
        if(this.selects.length == 0){
          this.$qzfMessage("请选择", 1)
          return
        }
        this.selects.map(v=>{
          v.status = 2
        })
        assetTotalSaves(this.selects).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("修改成功")
            this.getList()
          }
        })
      }else if(command == 'e'){
        jzAsset().then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("结转成功")
            this.getList()
          }
        })
      }else if(command == 'f'){
        if(this.selects.length == 0){
          this.$qzfMessage("请选择", 1)
          return
        }
        let status = false
        this.selects.map(v=>{
          if(v.clearPeriod){
            status = true
            return
          }
        })
        if(status){
          this.$message.error('存在已清理资产');
          return
        }
        let arr = []
        this.selects.map(v=>{
          this.list.map(asset=>{
            if(v.id == asset.id){
              arr.push({id:v.id*1,name:asset.name,clearSubjectId:"",originalSubjectId:""})
            }
          })
        })
        this.$refs.clearAssetsRef.init(arr)
      }
    },
    handleSelectionChange(val) {
      this.selects = val;
      this.ids = []
      val.map(item=>{
        this.ids.push( item.id )
      })
    },
    assetsDelete(){
      if(this.selects.length == 0){
        this.$qzfMessage("请选择", 1)
        return
      }
      let fail = this.selects.find(v=>v.clearPeriod)
      if(fail){
        this.$qzfMessage(`${fail.name}已清理，无法删除，请重新选择！`, 1)
        return
      }
      this.$confirm('确认删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteEaAssets(this.selects).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("删除成功")
          this.getList()
        }
        })
      })
    },
    handleModifyStatus(row){
      this.$confirm('确认删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteEaAssets([row]).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("删除成功")
          this.getList()
        }
       })
      })
    },
    handleUpdate(row){
      this.$refs.addAssets.openDialog(row)
    },
    openCom(){
      this.$refs.importBck.handImport()
    },
    getSummaries(param){
      let { columns, data } = param;
      data = this.sumList
      let amortizeAmount = 0
      let count = 0

      let initLjDepreciation = 0
      let ljDepreciation = 0

      let netValue = 0
      let originalValue = 0
      let cz = 0
      
      amortizeAmount = data.amortizeAmount
      count = data.count

      initLjDepreciation = data.initLjDepreciation
      ljDepreciation = data.ljDepreciation

      netValue = data.netValue
      originalValue = data.originalValue
      cz = data.cz
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }if(column.property == "amortizeAmount"){
          sums[index] = amortizeAmount
        }else if(column.property == "count"){
          sums[index] = count
        }else if(column.property == "initLjDepreciation"){
          sums[index] = initLjDepreciation
        }else if(column.property == "ljDepreciation"){
          sums[index] = ljDepreciation
        }else if(column.property == "cz"){
          sums[index] = cz
        }else if(column.property == "netValue"){
          sums[index] = netValue
        }else if(column.property == "originalValue"){
          sums[index] = originalValue
        }else {
          sums[index] = '';
        }
      });
      return sums;
    },
    printAssets(){
      this.$refs.printVouch.init([this.comId],this.listQuery.period,this.ids)
    },
    exportAssets(){
      let param = {
        bookNames:['assets'],
        type:'pdf',
        query:this.listQuery,
        ids:this.ids,
        comIds:[this.comId],
        period:this.listQuery.period
      }
      param.query.comIds = [this.comId]
      exportFixedAssets(param).then(res => {
        if (res.data.msg == "success") {
          window.open(res.data.data.url); 
        }
      })
    },
    handleClick(tab) {
      // //console.log(tab.props.label);
      this.title=tab.props.label;
      if(tab.props.name == 2){
        this.listQuery.type = 2
      }else if (tab.props.name == 3){
        this.listQuery.type = 3
      }else if (tab.props.name == 1){
        this.listQuery.type = 1
      }
      this.getList()
    },
    //重置
    cancel(){
      let originLimit = this.listQuery.limit
      let originType = this.listQuery.type
      this.listQuery= {
        assetType: '',
        page: 1,
        limit: originLimit,
        type: originType,
        comId:this.$store.getters['user/comInfo'].comId*1,
        period:this.$store.getters['user/comInfo'].period,
        status:5,
        depOk:0
      }
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.right_btns{
  
  float: right;
  text-align: right;
  margin-bottom: 10px;
}
.left_search{
  
  float: left;
  text-align: left;
}
.inconStyle{
  // margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor,#17a2b8);
  font-size: 18px;
  line-height: 24px;
}
.pagination{
  margin-top: 15px;
}
.content_data {
  background-color: #d8ecff;
  margin-bottom:10px
}
.each {
  width: 15%;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}
.each span {
  font-size: 13px;
  font-weight: 600;
  color: #000;
}
.each a {
  text-decoration-line: underline;
  text-decoration-color: #141720;
}
.icon_bg {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #aedcfb;
  text-align: center;
  line-height: 35px;
  .iconfont {
  font-size: 18px;
  // color: var(--themeColor, #17a2b8);
  color: #17a2b8;
}
}
</style>