<template>
  <el-dialog :close-on-click-modal="false" destroy-on-close title="固定资产清理" v-model="clearStatus" width="70%"
    append-to-body>
    <el-table border :data="clearTemp" stripe style="width: 100%">
      <el-table-column label="资产名称" align="center" min-width="120">
        <template #default="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>

      <el-table-column label="资产清理科目" align="center" min-width="150">
        <template #default="scope">
          <subject-list v-model:subjectId="scope.row.clearSubjectId" v-model:fzhsItemId="scope.row.clearFzhsId"
            :codes="$findCode(['code1606'])"></subject-list>
        </template>
      </el-table-column>

      <el-table-column label="资产原值科目" align="center" min-width="150">
        <template #default="scope">
          <subject-list v-model:subjectId="scope.row.originalSubjectId" v-model:fzhsItemId="scope.row.originalFzhsId"
            :codes="$findCode(['code1601'])"></subject-list>
        </template>
      </el-table-column>

      <el-table-column label="清理备注" align="center" min-width="140">
        <template #default="scope">
          <el-radio v-model="scope.row.clearRemark" label="出售">出售</el-radio>
          <el-radio v-model="scope.row.clearRemark" label="损毁">损毁</el-radio>
          <el-radio v-model="scope.row.clearRemark" label="报废">报废</el-radio>
        </template>
      </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="clearStatus = false" size="small">取 消</el-button>
        <el-button type="primary" @click="clearClick" size="small">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, getCurrentInstance } from 'vue'
import { ElMessage } from 'element-plus'
import { clearAsset } from '@/api/assets'
const { proxy } = getCurrentInstance()
const emit = defineEmits(['success'])
const clearTemp = ref([])
const clearStatus = ref(false)
const init = (item) => {
  clearTemp.value = item
  clearStatus.value = true
}
const clearClick = () => {
  let fail = clearTemp.value.find(v => !v.clearSubjectId || !v.originalSubjectId || !v.clearRemark)
  if (fail) {
    ElMessage.warning('请补充完整')
    return
  }
  clearAsset(clearTemp.value).then(res => {
    if (res.data.msg == 'success') {
      clearStatus.value = false
      ElMessage.success('清理成功')
      emit('success')
      proxy.$bus.emit('voucherUpdate')
      proxy.$bus.emit('assetsUpdate')
    }
  })
}
defineExpose({ init })
</script>

<style scoped lang="less"></style>
